/*******************************************************************************
 * Variables used throughout the theme.
 * To adjust anything, simply edit the variables below and rebuild the theme.
 ******************************************************************************/

// Colors
$red-color: #ff3636 !default;
$red-color-dark: #b71c1c !default;
$orange-color: #f29105 !default;
$blue-color: #0076df !default;
$blue-color-dark: #00369f !default;
$cyan-color: #00c3ff !default;
$light-cyan-color: lighten($cyan-color, 25%);
$green-color: #00ab37 !default;
$green-color-lime: #b7d12a !default;
$green-color-dark: #009f06 !default;
$green-color-light: #ddffdd !default;
$green-color-bright: #11d68b !default;
$purple-color: #b509ac !default;
$light-purple-color: lighten($purple-color, 25%);
$pink-color: #f92080 !default;
$pink-color-light: #ffdddd !default;
$yellow-color: #efcc00 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: #212124;
$grey-900: #212529;

$white-color: #ffffff !default;
$black-color: #000000 !default;

// Theme colors

$code-bg-color-light: rgba($purple-color, 0.05);
$code-bg-color-dark: #2c3237 !default;
